/***
 * GERAL
 ***/
$(function() {
    // Menu toggle
    var $win    = $(window);
        $header = $('header');
        $menu   = $('header .menu');

    $('body').on('click', '.menuToggle', function(event) {
        event.preventDefault();
        $menu.slideToggle( "slow" );
        $('html').toggleClass('menu-open');
    });

    $('body').on('click', 'header .search', function(event) {
        event.preventDefault();
        $('.form-search').slideToggle( "slow" );
        $('.form-search').focus();
    });

    if ($win.width() <= 767) { 
        $('body').on('click', 'li.sub', function(event) {
            // event.preventDefault();
            $(this).children('.sub-menu').slideToggle("slow");
        });
    };

    $win.resize(function(event) {
        $('html').removeClass('menu-open');
        if ($win.width() <= 767) {
            $menu.css('display', 'none');
        } else {
            $menu.css('display', 'inline-block');
        };
    });

    $(window).scroll(function () {
        if ($(window).scrollTop() > 149) {
            $('header').addClass('fixed');
        } else {
            $('header').removeClass('fixed');
        }
    });

    // Banner
    $('.banner .owl-carousel').owlCarousel({
        items           : 1,
        loop            : true,
        dots            : true,
        autoplay        : true,
        autoplayTimeout : 10000,
        nav             : true,
        navText         : ['<i class="icon-left"></i>', '<i class="icon-right"></i>'],
    });       

    // Parceiros
    $('.slider-for').slick({
        slidesToShow    : 1,
        slidesToScroll  : 1,
        arrows          : false,
        fade            : true,
        asNavFor        : '.slider-nav',
    });

    $('.slider-nav').slick({
        slidesToShow    : 5,
        slidesToScroll  : 1,
        asNavFor        : '.slider-for',
        dots            : false,
        focusOnSelect   : true,
    });

    // Input masks
    $('input.telefone').focusout(function(){
        var phone, element;
        element = $(this);
        element.unmask();
        phone = element.val().replace(/\D/g, '');
        if(phone.length > 10) {
            element.mask("(99) 99999-999?9");
        } else {
            element.mask("(99) 9999-9999?9");
        }
    }).trigger('focusout');

    // Mapa
    $(".mapa.m2").gmap3({
        map: {
            options: {
                scrollwheel: false,
                zoom: 15,
                streetViewControl: false,
                panControl: true,
                mapTypeControl: false,
                center: [-21.255375, -48.316989],
            }
        },
        marker: {
            latLng: [-21.255375, -48.316989]
        }
    });
    // Mapa
    $(".mapa.m1").gmap3({
        map: {
            options: {
                scrollwheel: false,
                zoom: 15,
                streetViewControl: false,
                panControl: true,
                mapTypeControl: false,
                center: [-23.559807, -46.656855],
            }
        },
        marker: {
            latLng: [-23.559807, -46.656855]
        }
    });

    // Tabs
    $(".vagas .item .item-head").click(function(event) {
        event.preventDefault();
        if ($(this).parent().hasClass('active')) {
            $('.vagas .item').removeClass('active');
            $(this).parent().removeClass('active');
        } else {
            $('.vagas .item').removeClass('active');
            $(this).parent().addClass('active');
        }
    });

    // Assunto no form vaga
    $(".vagas .item").each(function(index, el) {
        vaga = $(this).data('vaga');

        $(this).find("form input[name='assunto']").val(vaga);
    });
});


// Video Embed
var videoEmbed = {
    invoke: function(){

        $('.embed').html(function(i, html) {
            return videoEmbed.convertMedia(html);
        });

    },
    convertMedia: function(html){
        var pattern1 = /(?:http?s?:\/\/)?(?:www\.)?(?:vimeo\.com)\/?(.+)/g;
        var pattern2 = /(?:http?s?:\/\/)?(?:www\.)?(?:youtube\.com|youtu\.be)\/(?:watch\?v=)?(.+)/g;

        if(pattern1.test(html)){
           var replacement = '<iframe width="100%" height="100%" src="//player.vimeo.com/video/$1" frameborder="0" webkitallowfullscreen mozallowfullscreen allowfullscreen></iframe>';
           var html = html.replace(pattern1, replacement);
        }

        if(pattern2.test(html)){
              var replacement = '<iframe width="100%" height="100%" src="https://www.youtube.com/embed/$1" frameborder="0" allowfullscreen></iframe>';
              var html = html.replace(pattern2, replacement);
        }

        return html;
    }
};